import { Home } from './pages/Home';

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
];

export default AppRoutes;
