import { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

interface IState {
  children: any;
}

export class Layout extends Component<IState> {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container tag='main'>{this.props.children}</Container>
      </div>
    );
  }
}
